export default defineNuxtRouteMiddleware((to, from) => {
  const { user, isAuthenticated } = useSanctumAuth();

  if (isAuthenticated.value && (!user.value?.profile || user.value?.profile?.addresses?.length === 0)) {
    return navigateTo({
      path: '/profile/add-address',
      query: { redirect: to.fullPath }
    });
  }
});
